body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0em;
}

/* Placeholder (at the top) */
.ProseMirror h1.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror:focus {
  outline: none;
 }
.ProseMirror ul, .ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1, .ProseMirror h2, .ProseMirror h3, .ProseMirror h4, .ProseMirror h5, .ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror h1 {
  font-size: 1.4em;
  margin-top: 0;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror h2 {
  font-size: 1.3em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror h3 {
  font-size: 1.2em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror h4 {
  font-size: 1.1em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror h5 {
  font-size: 1em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror h6 {
  font-size: .9em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror strong {
  font-weight: 700;
}
.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror a {
  color: rgb(113, 189, 236);
  text-decoration: underline;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
